import React, { Component } from "react"
import styled from 'styled-components'
import DisneyFooter from "./NewDisneyFooter"
import seeItSafety from "../../images/See-It-Safely-Logo.png"
import { Container, Row, Col} from "reactstrap";
import {media} from "utils/Media"

const FooterStyled = styled.footer`
    background-color: white;
    width: 100%;
    position: relative;
    z-index: 5;
    @media ${media.xl} {
      .container {
        max-width: 1400px !important;
      }
    }
`

const SocialIcons = styled.div`
    padding-top: 2rem;
    text-align: center;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
`

const SocialIcon = styled.a`
    margin: 0 1rem;
    text-decoration: none;
    color: #003199;
    font-size: 2rem;

    &:hover {
        text-decoration: none;
        color: black;
    }
`

class Footer extends Component {
    render() {
        return (
            <FooterStyled className="footer">
              <Container>
                <Row>
                  <Col md={12}>
                    <SocialIcons>
                        <SocialIcon
                            href="https://www.facebook.com/frozenlondon/"
                            className="icon icon-facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="sr-only">facebook</span>
                        </SocialIcon>

                        <SocialIcon
                            href="https://twitter.com/frozenlondon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span><svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1226.37 1226.37">
  <path d="M727.348 519.284 1174.075 0h-105.86L680.322 450.887 370.513 0H13.185l468.492 681.821L13.185 1226.37h105.866l409.625-476.152 327.181 476.152h357.328L727.322 519.284zM582.35 687.828l-47.468-67.894-377.686-540.24H319.8l304.797 435.991 47.468 67.894 396.2 566.721H905.661L582.35 687.854z"/>
</svg></span>
                            <span className="sr-only">twitter</span>
                        </SocialIcon>

                        <SocialIcon
                            href="http://instagram.com/frozenlondon"
                            className="icon icon-instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="sr-only">instagram</span>
                        </SocialIcon>

                        {/* <SocialIcon href="https://youtube.com" className="icon icon-youtube" target="_blank" rel="noopener noreferrer"></SocialIcon>
                        <SocialIcon href="https://spotify.com" className="icon icon-spotify" target="_blank" rel="noopener noreferrer"></SocialIcon> */}
                        <SocialIcon
                            href="https://officiallondontheatre.com/see-it-safely/"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="p-0 pb-4"
                        >
                                <img src={seeItSafety} alt="See It Safety" title="See It Safety" className="seeItSafety"/>
                        </SocialIcon>
                    </SocialIcons>

                    <DisneyFooter />
                  </Col>
                </Row>
              </Container>
            </FooterStyled>
        )
    }
}
export default Footer



