import React, {Component} from 'react';
import {Link} from "gatsby";
import styled from "styled-components";
import {media} from "utils/Media"
import snowFlakeTop from "../../images/snow-flake-menu-top.svg"
import snowFlakeBottom from "../../images/snow-flake-menu-bottom.svg"

const Nav = styled.nav`
    transition: opacity .2s ease-in-out;
    position: absolute;
    top: 5px;
    bottom: 0;
    left: 0;
    z-index: 500;
    max-width: initial;
    transform: translateX(-100%);
    opacity: 0;
    width: 100%;
    background: white;
    display: unset;

    @media ${media.md} {
        width: 450px;
    }

    ul {
        position: sticky;
        top: 70px;
        z-index: 502;
        margin-top: 9rem;
        max-height: calc(100vh - 70px);
        margin-bottom: 0;
        text-align: left;
        overflow-y: auto;
        padding-bottom: 4rem;
        padding-left: 0;
        margin-right: -17px;
        width: 100%;
        height: 100%;

        @media ${media.md} {
            margin-right: 0;
            width: 100%;
        }

        &.navToggled {
            width: calc(100% + 17px);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            content: "";
            z-index: 503;
            width: 100%;
            height: 100%;
            background: url("${snowFlakeTop}") no-repeat right top;
            background-size: 200px;
        }

         &::after {
            position: absolute;
            top: 50px;
            left: 0;
            content: "";
            z-index: 503;
            width: 100%;
            height: calc(100% - 70px);
            background: url("${snowFlakeBottom}") no-repeat -20% bottom;
            background-size: 200px;
        }

        li {
            display: block;
            margin: 1rem 0;
            position: relative;
            width: calc(100% - 17px);
            padding-left: 15px;
            z-index: 504;

            @media ${media.md} {
                width: 100%;
            }

            &.bg {
                padding-left: 0;
                div {
                    background: linear-gradient(180deg, rgba(129, 224, 255, 0.5) 0%, rgba(129, 224, 255, 0) 100%);
                }
            }

            &.bigger span {
                font-size: 1.25rem;
                text-transform: uppercase;
                font-weight: bold;
                line-height: 150%;
                color: #000;

                @media ${media.sm} {
                    font-size: 1.75rem;
                }
            }

            .link {
                color: #000;
                font-style: normal;
                font-size: 1rem;
                line-height: 150%;
                font-weight: normal;

                @media ${media.sm} {
                    font-size: 1.25rem;
                }

                &.active, &:hover {
                    color: #3400A3;
                    text-decoration: none;
                }
            }

            button {
                background: none;
                border: none;
                padding: 0;
            }
        }
    }

    .submenu {
        display: none;
        margin: 0 auto;
        padding: 0;
        .link {
            font-size: 0.875rem;
            font-weight: bold;

            @media ${media.sm} {
                font-size: 1.125rem;
            }
        }

        &.visible {
            display: block;
        }
    }

    &.enrichment {
        ul {
            margin-top: 0;
            height: calc(100vh - 70px) !important;
            background: white;
            position: relative !important;
        }
    }

    &.active {
        transform: translateX(0);
        opacity: 1;
    }
`

class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            subMenuVisible: false
        }
    }

    toggleSubMenu = () => {
        this.setState({subMenuVisible: !this.state.subMenuVisible})
    }

    render() {
        return (
            <Nav id="navbarSupportedContent"
                 className={`nav ${this.props.navToggled ? 'active' : ''} ${this.props.enrichment ? 'enrichment' : ''} ${this.props.collapsed ? 'collapsed' : ''}`}>
                <ul className={`nav__btns ${this.props.navToggled ? 'navToggled' : ''}`}>
                    <li>
                        <Link to="/" className="link font-weight-bold" activeClassName="active">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/ticket-information/" activeClassName="active" className="link font-weight-bold">
                            Ticket Information
                        </Link>
                    </li>
                    <li>
                        <Link to="/access/" activeClassName="active" className="link font-weight-bold">
                            Access Performances
                        </Link>
                    </li>
                    <li>
                        <Link to="/groups/" activeClassName="active" className="link font-weight-bold">
                            Groups & Education
                        </Link>
                    </li>
                    <li>
                        <Link to="/gallery/" className="link font-weight-bold" activeClassName="active">
                            Gallery
                        </Link>
                    </li>
                    <li>
                        <Link to="/faqs/" className="link font-weight-bold" activeClassName="active">
                            FAQ
                        </Link>
                    </li>
                    <li>
                        <Link to="/cast-and-creatives/" className="link font-weight-bold" activeClassName="active">
                            Cast & Creatives
                        </Link>
                    </li>
                    <li className="bg">
                        <div>&nbsp;</div>
                    </li>
                    <li>
                        <span className="link font-weight-bold text-uppercase">Discover More</span>
                    </li>
                    <li>
                        <a className="link no-interstitial-check" href="https://www.frozeneducation.co.uk/">
                            Beyond Frozen: Education Resources
                        </a>
                    </li>
                    <li>
                        <Link to="/bringing-frozen-to-life/" activeClassName="active" className="link">
                            Bringing FROZEN To Life - Norwegian Style
                        </Link>
                    </li>
                    <li>
                        <Link to="/let-it-go/" activeClassName="active" className="link">
                            What Makes ‘Let It Go’ So Catchy?
                        </Link>
                    </li>
                </ul>
            </Nav>
        );
    }
}

export default Navigation;
