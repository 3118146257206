import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {StaticQuery, graphql} from "gatsby"
import shareImage from 'images/share-image.jpg'

function SEO({description, lang, meta, keywords, title, slug}) {
  
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const site = data.site;
        const metaDescription = description || site.siteMetadata.description
        let siteTitle = `${site.siteMetadata.title}`
        
        let url = typeof slug === 'undefined' ? site.siteMetadata.siteUrl : `${site.siteMetadata.siteUrl}/${slug}/`
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`${siteTitle} | %s`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: `${siteTitle} | ${title}`,
              },
              {
                property: `og:site_name`,
                content: site.siteMetadata.title,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: "website",
              },
              {
                property: `og:image`,
                content: site.siteMetadata.siteUrl + shareImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: `${siteTitle} | ${title}`,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: site.siteMetadata.siteUrl + shareImage,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                  : []
              )
              .concat(meta)}
          >
            <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "Frozen the Musical",
                    "url": "https://frozenthemusical.co.uk/"
                }
            `}
            </script>
            <link rel="alternate" hrefLang="en-gb" href="https://frozenthemusical.co.uk"/>
            <link rel="alternate" hrefLang="en-us" href="https://frozenthemusical.com"/>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`
