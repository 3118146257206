import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';

import { media } from 'utils/Media';

import Background from '../../images/countdown/background.png';
import SnowFlake from '../../images/countdown/icicle.png';

const StyledCountdown = styled.section`
    width: 100%;
    padding:  1rem 0;
    background: linear-gradient(148deg, #4595E7 21.34%, #63DBE7 37.32%, #63DBE7 47.97%, #68B6DB 61.51%, #494BBA 75.83%);
    filter: blur(0px);
    font-variant-numeric: tabular-nums;
    position: relative;
    .sm-hidden {
        @media ${media.sm} {
            display: none;
        }
    }
    .xl-hidden {
        @media ${media.xl} {
            display: none;
        }
    }
    &:after {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);
        /* content: ''; */
    }
    .background-image {
        position: absolute;
        inset: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.backgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        mix-blend-mode: screen;
        /* opacity: 0.5; */
    }
    .wrapper {
        position: relative;
        z-index: 5;
        width: 90%;
        margin: 0 auto;
        max-width: 1150px;
        text-align: center;
        .icy {
            position: absolute;
            top: 0%;
            img {
                width: 58px;
                height: 58px;
                @media ${media.sm} {
                    width: 65px;
                    height: 65px;
                }
                @media ${media.md} {
                    width: 85px;
                    height: 85px;
                }
            }
        }
        .left {
            left: 0;
        }
        .right {
            right: 0;
        }
        p {
            color: #FFF;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-shadow: 0px 2px 2px black;
            margin: 0;
            padding: 0;
            @media ${media.sm} {
                font-size: 22px;
            }
            @media ${media.xl} {
                font-size: 24px;
            }
            span {
                font-family: 'Turquoise W05 Inline';
                color: #004684;
                text-align: center;
                text-shadow: 0px 2px 2px rgba(0, 70, 132, 0.40);
                font-size: 26px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media ${media.sm} {
                    font-size: 40px;
                }
                @media ${media.md} {
                    font-size: 60px;
                }
                @media ${media.xl} {
                    font-size: 70px;
                }
            }
        }
    }
`;

export default function CountdownBlock() {
    const targetDate = new Date('2024-09-08T17:30:00');

    const renderer = ({ days, hours, minutes }) => {
        return (
            <p>
                <span>{days}</span> days, <span>{hours}</span> hours, <span>{minutes}</span> minutes <br className='xl-hidden' />
                until our final show! <br className='sm-hidden' />
                8th September 5.30pm
            </p>
        );
    };

    return (
        <StyledCountdown backgroundImage={Background}>
            <div className='wrapper'>
                <span className='icy left'>
                    <img src={SnowFlake} alt="" />
                </span>
                <Countdown date={targetDate} renderer={renderer} />
                <span className='icy right'>
                    <img src={SnowFlake} alt="" />
                </span>
            </div>
            <div className='background-image' />
        </StyledCountdown>
    );
}
