import { navigate } from "gatsby"

export function handleLinkClick(event, link) {
    event.preventDefault();

    const element = event.currentTarget;
    const href = element.getAttribute("href")
    const siteUrl = "frozenthemusical.co.uk"
    let actionName = "link-click"

    if (element.className.includes("btn")) {
        actionName = "button-click"
    }

    // If href is a for the site itself exit
    if (href.indexOf(siteUrl) > 0 || (href.substring(0, 1) === "/" && href.substring(0, 2) !== "//")) {
        // Proceed with link as normal
        // DCF tracking
        console.log(actionName)
        const eventData = {
            actionName: actionName,
            actionValue1: "Sign Up For Tickets"
        }
        if (typeof window !== "undefined" && typeof window.__dataLayer !== "undefined") {
            window.__dataLayer.publish("click",eventData);
        }
        navigate(href);
    } else {
        // DCF tracking
        actionName = "exit-link"
        console.log(actionName)
        console.log(window.__dataLayer)
        const exitLinkEventData = {
            event_name: "click",
            actionName: "exit-link",
            actionValue: href
        }
        if (typeof window !== "undefined" && typeof window.__dataLayer !== "undefined") {
            window.__dataLayer.publish('click', exitLinkEventData);
        }
        // Redirect to interstitial with correct state
        navigate("/interstitial/", {
            state: {
                href: href,
            }
        });
    }
}