import React, {Component} from "react"
import styled from "styled-components"
import {media} from "utils/Media"
import {disneynewsletterJs} from "js/disney-newsletter.js"
import moment from 'moment'
import 'moment-timezone';
import Navigation from "./Navigation";
import VideoModal from "../shared/VideoModal";
import {haversine} from "../../js/geolocationDistance";

const scrollToElement = require('scroll-to-element');

const Relative = styled.div`
  position: relative;
  padding: 0 15px;

  @media ${media.lg} {
    display: flex;
    justify-content: space-between;
  }

  .downloadAllBtn {
    padding-left: 10px;
    padding-right: 5px;
    width: 240px;
    max-width: 240px;
    font-size: 1rem;
    margin-top: 10px;
    margin-right: 15px;
    float: right;

    @media ${media.lg} {
      float: none;
      margin-top: 15px;
      width: 300px;
      max-width: 300px;
    }

    img {
      padding-left: 3px;
    }
  }
`

const HeaderContainer = styled.div`
  background: white;
  display: block;
  position: absolute;
  top: 68px;
  width: 100%;
  max-width: 1920px;
  padding: 0;
  transition: background .2s ease-in-out;
  z-index: 501;
  min-height: 60px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

  @media ${media.sm} {
    min-height: 50px;
    box-shadow: none;
    border-top: 1px solid;
  }

  @media ${media.lg} {
    min-height: 70px;
  }

  &.collapsed {
    top: 0;
    position: fixed;
  }

`

const NavToggle = styled.button`
  position: relative;
  z-index: 502;
  height: 30px;
  width: 30px;
  margin-left: 0;
  margin-top: 15px;
  transition: all .2s linear;
  cursor: pointer;
  padding: 0;
  border: none;
  background: linear-gradient(135deg, #7BF6EF 0%, #3400A3 100%);

  @media ${media.sm} {
    height: 40px;
    width: 40px;
    margin-top: 5px;
  }

  @media ${media.lg} {
    margin-top: 15px;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 501;
    opacity: 0;
    transition: opacity 0.2s linear;
    background: linear-gradient(135deg, #9A8AFF 0%, #3B1293 48.96%, #000355 100%);
  }

  :hover::before {
    opacity: 1;
  }

  span {
    width: 19px;
    height: 2px;
    display: block;
    background-color: white;
    position: absolute;
    transition: all .2s ease-in-out;
    left: 6px;
    z-index: 503;

    &:nth-child(1) {
      top: 9px;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 9px;
    }

    @media ${media.sm} {
      left: 8px;
      width: 24px;
      height: 3px;

      &:nth-child(1) {
        top: 11px;
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(3) {
        bottom: 11px;
      }
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 50%;
        bottom: auto;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

`

const CounterStyled = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${media.sm} {
    width: 470px;
    top: 5px;
  }

  @media ${media.lg} {
    width: 900px;
    text-align: left;
    right: 15px;
    top: 15px;
  }

  &.active {
    //right: 30px;
  }

  &.counterOver {
    width: 320px;
    top: 15px;

    @media ${media.sm} {
      width: 470px;
      top: 5px;
    }

    @media ${media.lg} {
      width: 900px;
      top: 15px;
    }

    a {
      margin: 0 0 0 auto;
    }
  }

  .counter-text {
    font-size: 14px;
    font-weight: bold;
    color: ${props => props.theme.colors.navy};

    @media ${media.lg} {
    }
  }

  .counter-item {
    line-height: 1rem;
    position: relative;
    padding-right: 5px;
    width: 60px;

    &:first-of-type {
      width: 65px;
      flex-grow: 1;
    }

    &:not(:first-of-type) {
      flex-shrink: 0;
    }

    &.faded {
      opacity: 0.3;
    }

    .divider {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50px;
    }
  }

  .number {
    font-size: 2rem;
    line-height: 1rem;
    font-weight: bold;
    font-family: 'Proxima Nova W05 Bold', Arial, sans-serif;
    color: ${props => props.theme.colors.navy};
    display: inline-block;
    width: 56px;
    text-align: center;
    padding-top: 5px;

    @media ${media.lg} {
      padding-top: 0;
      font-size: 1.75rem;
      line-height: 1.5rem;
    }
  }

  .copy {
    font-size: 12px;
    color: ${props => props.theme.colors.navy};
    text-transform: uppercase;
    display: inline-block;
    width: 56px;
    text-align: center;
  }

  .counter-left-side {
    @media ${media.lg} {
      width: 700px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`

const PriorityButton = styled.a`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: ${props => props.theme.font.family.bold};
  font-weight: bold;
  transition: all .25s ease-in-out;
  margin-top: 0 !important;

  @media ${media.sm} {
    width: 200px;
    height: 40px;
  }

  @media ${media.lg} {
    margin-left: 10px !important;
  }

  &.smaller {
    width: 200px;
    height: 40px;
  }
`

const TrailerButton = styled.button`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: ${props => props.theme.font.family.bold};
  font-weight: bold;
  transition: all .25s ease-in-out;
  margin-top: 0 !important;

  @media ${media.sm} {
    width: 200px;
    height: 40px;
  }

  @media ${media.lg} {
    margin-left: 10px !important;
  }

  &.smaller {
    width: 200px;
    height: 40px;
  }
`

class Header extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      navToggled: false,
      collapsed: false,
      disneynewsletterjs: false,
      storyDropdownOpen: false,
      teachingDropdownOpen: false,
      videoModalOpen: false,
      showImages: false
    };
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true)
    window.addEventListener('resize', this.handleScroll, true)
    
    if (!this.state.disneynewsletterjs) {
      const s = document.createElement('script')
      s.appendChild(document.createTextNode(disneynewsletterJs))
      document.body.appendChild(s)
      
      this.setState({
        disneynewsletterjs: !this.state.disneynewsletterjs
      })
    }
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
    window.removeEventListener('resize', this.handleScroll, true)
  }
  
  handleScroll = () => {
    // let scrollPosition = document.documentElement.scrollTop
    let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
    let collapsed = scrollPosition < 63 ? false : true
    this.setState({
      collapsed
    })
  }
  
  toggleNav = () => {
    this.setState({
      navToggled: !this.state.navToggled
    })
  }
  
  scrollDown = (id, offset = 0) => {
    scrollToElement(id, {
      duration: 300,
      offset: offset
    })
    
    this.setState({
      navToggled: false
    })
  }
  
  onClick = (e) => {
    if (typeof window !== 'undefined') {
      window.did.launchNewsletters('frozen')
        // this API returns a Promise object (thenable)
        // the first function passed to then() is success(data)
        .then(function (data) {
          console.log(data);
        })
        // the second function is an error handler (optional)
        .fail(function (error) {
          console.log(error);
        });
      
    }
  }
  
  toggleVideoModal = (e, src) => {
    console.log(e, src)
    e.preventDefault();
    
    this.setState({
      src: src,
      videoModalOpen: !this.state.videoModalOpen
    });
  }
  
  render() {
    const {navToggled, collapsed} = this.state
    
    return (
      <React.Fragment>
        <HeaderContainer className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}>
          <Relative>
            <NavToggle
              className={`${navToggled ? 'active' : ''} ${collapsed ? 'collapsed' : ''}`}
              onClick={this.toggleNav}
              aria-expanded="false"
              aria-controls="navbarSupportedContent"
              type="button"
              aria-label="Toggle navigation"
            >
              
              <span/>
              <span/>
              <span/>
            </NavToggle>
            
            <CounterStyled
              className={`${moment().isAfter(moment('2020-10-30T19:30:00.000Z')) && 'counterOver'} ${navToggled ? 'active' : ''}`}>
              <div className="counter-left-side">
                <div className="d-flex ml-md-1">
                  <PriorityButton className="btn btn--new"
                                  href="https://booking.lwtheatres.co.uk/event/2c62fa9a-9025-ea11-a9ca-064c84cbe4ea/">
                    <span className="d-none d-sm-inline-block">Book tickets</span>
                    <span className="d-sm-none">Tickets</span>
                  </PriorityButton>
                </div>
              </div>
            </CounterStyled>
          </Relative>
        </HeaderContainer>
        <Navigation
          collapsed={collapsed}
          navToggled={navToggled}
        />
        <VideoModal toggleModal={this.toggleVideoModal} title={this.state.title} src={this.state.src}
                    modalOpen={this.state.videoModalOpen}/>
      </React.Fragment>
    )
  }
}

export default Header
